.documents-uploader-radio-buttons-container {
  @apply flex gap-4;
}

.documents-uploader-radio-buttons-container label {
  @apply hover:cursor-pointer flex;
}

.documents-uploader-radio-buttons-container input {
  @apply w-4;
}