.breadcrumb {
    @apply flex justify-between items-center w-fit;
}

.breadcrumb-element {
    @apply text-light-grey text-lg;
}

.breadcrumb-last {
    @apply text-black text-xl;
}

.breadcrumb a {
    @apply flex justify-between items-center w-fit;
}

.breadcrumb-prefix {
    @apply pr-2;
}

.breadcrumb-body {
    @apply pr-2;
}

.breadcrumb-suffix {
    @apply pr-2;
}

.breadcrumb-separator {
    @apply pr-2;
}