.rfs-select-container {
    @apply w-min w-48 lg:w-60 text-sm lg:text-lg px-2 hover:cursor-pointer;
}

.rfs-control-container,
.rfs-menu-container{
    @apply bg-white rounded-md !important;
}
.rfs-menu-container{
    @apply py-1 !important;
}

.rfs-option:hover:not(.rfs-option-disabled):not(.rfs-option-selected) {
    @apply bg-pale-grey !important;
}
