.admin-menu {
    @apply flex items-center ml-12 md:ml-1 my-0 text-dark-grey font-normal list-none h-full;
}

.admin-menu-small {
    @apply flex-col items-center px-4 my-0 text-dark-grey font-normal list-none h-fit;
}

.admin-menu li {
    @apply flex hover:cursor-pointer h-full items-center px-4;
}

.admin-menu li samp {
    @apply block;
}

.admin-menu li.active {
    @apply border-b-4 border-bright-blue-dark text-bright-blue-dark;
}

.admin-menu span.active {
    @apply translate-y-0.5;
}