.notauth-page {
    @apply flex min-h-full h-full;
}

.notauth-box {
    @apply bg-no-repeat bg-cover m-auto flex items-center min-h-full min-w-full h-full;
}

.notauth-tile{
    @apply bg-white w-96 h-fit ml-24 mt-16;
}

.notauth-logo{
    @apply flex justify-between m-7;
}

.notauth-message {
    @apply px-7 text-sm text-red;
}

.notauth-button{
    @apply flex justify-between items-center pr-2 py-1;
}