.contract-creator-release-state-label-commissioned,
.contract-creator-release-state-label-draft,
.contract-creator-release-state-label-inactive,
.contract-creator-release-state-label-inactive_deadline,
.contract-creator-release-state-label-invalid,
.contract-creator-release-state-label-to_be_released{
    @apply bg-black text-white;
}

.contract-creator-release-state-label-released {
    @apply bg-success text-white;
}

.icon {
    @apply w-4 ml-2 -translate-y-0.5;
}