.ant-popover-inner {
  @apply w-60;
}

.ant-popover-message {
  @apply flex items-start;
}

.ant-popover-message svg {
  @apply w-8 mt-1;
}


.ant-popover-message-title {
  @apply pl-2 -mt-0.5;
}

.ant-btn:first-of-type:not([disabled]) {
  @apply bg-white border-2 border-bright-blue text-bright-blue hover:cursor-pointer rounded;
}

.ant-btn:hover {
  @apply bg-white border-2 border-bright-blue text-bright-blue hover:cursor-pointer rounded;
}

.ant-btn:nth-of-type(2) {
  @apply text-white bg-bright-blue border-0 hover:cursor-pointer rounded;
}