.ant-checkbox-checked .ant-checkbox-inner::after {
    @apply border-dark-grey;
}

.ant-checkbox-checked .ant-checkbox-inner {
    @apply bg-white;
}

.ant-checkbox-checked:after {
    @apply border-none;
}

.ant-checkbox:hover,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    @apply border-dark-grey;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input + .ant-checkbox-inner {
    @apply border-dark-grey;
}