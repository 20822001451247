@import '~antd/dist/antd.css';

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: dbdr;
    src: url("./fonts/dbdr.ttf") format("truetype");
}

* {
    @apply font-db;
}

#root{
    @apply min-h-full h-full;
}

input,
select {
    @apply m-1 w-4/6 px-2.5 rounded-md border border-dark-grey bg-white;
}

button {
    @apply m-1 rounded-md px-5;
}

span.label {
    @apply m-1 w-2/6;
}

button.primary {
    @apply text-white bg-bright-blue border-0 hover:cursor-pointer;
}

button.primary-with-icon {
    @apply text-white bg-bright-blue border-0 hover:cursor-pointer flex items-center justify-center gap-2 h-10;
}

button.primary-with-icon-tablet {
    @apply text-white bg-bright-blue border-0 hover:cursor-pointer flex items-center justify-center gap-2 h-8;
}

button.secondary {
    @apply text-white bg-light-grey border-0 hover:cursor-pointer;
}

button.tertiary {
    @apply text-bright-blue bg-white border-0 hover:cursor-pointer;
}

button.white {
    @apply h-8 bg-white border-2 border-bright-blue text-bright-blue hover:cursor-pointer;
}

button.white-with-icon {
    @apply flex items-center justify-between gap-2 h-10 bg-white border-2 border-bright-blue text-bright-blue hover:cursor-pointer;
}

button.disable {
    @apply secondary progress;
}

.progress {
    @apply hover:cursor-progress;
}

button.tertiary-bordered {
    @apply text-bright-blue items-center bg-white border border-bright-blue hover:cursor-pointer;
}

button.tertiary-bordered-grey {
    @apply text-bright-blue items-center bg-white border border-light-grey hover:cursor-pointer;
}

button.warning {
    @apply flex items-center h-10 bg-white border-2 border-red text-red hover:cursor-pointer;
}

button.warning-with-icon {
    @apply flex items-center justify-between h-10 gap-2 bg-white border-2 border-red text-red hover:cursor-pointer;
}

button.disabled-bordered {
    @apply items-center h-10 bg-light-grey-light border-2 border-light-grey;
}

button.red-with-icon {
    @apply flex items-center justify-between gap-2 h-10 bg-red text-white hover:cursor-pointer;
}

button.green-with-icon {
    @apply bg-green text-white flex items-center justify-between gap-2 hover:cursor-pointer;
}