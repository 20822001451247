#tiles {
    @apply m-auto flex justify-items-center;
}

#tiles > div {
    @apply w-max m-auto
}

#tiles > div > div > div {
    @apply grid gap-y-7 gap-x-12 md:gap-x-6 justify-around content-between w-max m-auto;
    @apply sm:grid-cols-1;
    @apply md:grid-cols-2;
}

.three-cols > div > div > div {
    @apply xl:grid-cols-3 !important;
}

#tiles > div > div > div > br {
    @apply hidden;
}

#tiles > div > div > div > div {
    @apply xl:max-w-lg bg-white;
}

#tiles > div > div > div > div > img {
    @apply max-w-full min-w-full max-h-32 overflow-hidden block m-auto;
    @apply md:max-h-36;
    @apply lg:max-h-44;
}

#tiles > div > div > div > div > div {
    @apply max-w-sm bg-white px-5;
}

#tiles > div > div > div > div > div h1 {
    @apply text-xl my-4;
}

#tiles > div > div > div > div > div div {
    @apply text-sm my-2;
}

#tiles > div > div > div > div > div button,
#tiles > div > div > div > div > div a {
    @apply my-5 text-bright-blue bg-white border border-solid border-bright-blue rounded-md py-2 px-5;
}
