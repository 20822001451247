button.open-report {
    @apply w-max;
}
button.open-report span{
    @apply md:text-xs md:px-1;
    @apply lg:text-base lg:px-2;
}
button.open-report svg{
    @apply md:h-4 md:mt-1;
    @apply lg:h-4 lg:ml-2;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    @apply z-50 bg-black/[.25] !important;
}

.report-modal {
    @apply flex flex-row justify-center items-center h-screen font-normal bg-black/[.75];
}

.report-modal-box {
    @apply w-144 h-80 shadow-xl border border-solid rounded-lg bg-white;
}

.report-modal-title {
    @apply flex justify-between border-b border-light-grey p-1;
}

.report-modal-title h2 {
    @apply text-black text-2xl font-light pl-2 my-2.5;
}

.report-modal-title .close {
    @apply pt-2 pr-2;
}

.report-form {
    @apply border-b border-light-grey p-1 h-48 mt-4 px-6;
}

.report-form h3 {
    @apply pl-2 text-base text-dark-grey;
}

.report-form .report-list {
    @apply w-full text-xl;
}

.report-form .rfs-select-container {
    @apply w-full text-base w-full;
}

.report-subform {
    @apply mt-4 px-1 w-full;
}

.report-subform h4 {
    @apply text-base text-dark-grey;
}

.report-buttons {
    @apply flex justify-end px-1 py-2 content-center;
}

.single-date {
    @apply rounded-md;
}