.contract-label {
  @apply flex items-center px-3 rounded-xl pt-1 text-sm lg:text-base;
}

.contract-label-state{
  @apply block mt-auto mb-auto mr-0 ml-0;
}


.release-state-label-inactive_deadline,
.release-state-label-commissioned,
.contract-state-label-not_confirmed,
.contract-state-label-not_confirmed_inactive_deadline {
  @apply bg-amber-light text-amber-dark;
}

.release-state-label-inactive_deadline .icon,
.release-state-label-commissioned .icon,
.contract-state-label-not_confirmed .icon,
.contract-state-label-not_confirmed_inactive_deadline .icon {
  @apply fill-amber-dark;
}

.release-state-label-draft {
  @apply bg-light-grey-light text-light-grey-dark;
}

.release-state-label-draft .icon {
  @apply fill-light-grey-dark;
}

.release-state-label-inactive,
.release-state-label-invalid,
.contract-state-label-expired,
.contract-state-label-revoked,
.contract-state-label-approved_until_deadline,
.contract-state-label-expired_inactive_deadline {
  @apply bg-red-light text-red-dark;
}

.release-state-label-inactive .icon,
.release-state-label-invalid .icon,
.contract-state-label-expired .icon,
.contract-state-label-revoked .icon,
.contract-state-label-approved_until_deadline .icon,
.contract-state-label-expired_inactive_deadline .icon {
  @apply fill-red-dark;
}

.release-state-label-released,
.contract-state-label-confirmed,
.contract-state-label-confirmed_inactive_deadline,
.contract-state-label-approved_until_deadline_inactive_deadline {
  @apply bg-green-light text-green-dark;
}

.release-state-label-released .icon,
.contract-state-label-confirmed .icon,
.contract-state-label-confirmed_inactive_deadline .icon,
.contract-state-label-approved_until_deadline_inactive_deadline .icon {
  @apply fill-green-dark;
}

.release-state-label-to_be_released,
.contract-state-label-being_reviewed {
  @apply bg-bright-blue-light text-bright-blue-dark;
}

.release-state-label-to_be_released .icon,
.contract-state-label-being_reviewed .icon {
  @apply fill-bright-blue-dark;
}

.contract-state-label-undefined {
  @apply bg-amber-light text-amber-dark;
}

.contract-state-label-undefined .icon {
  @apply fill-amber-dark;
}

.category-label {
  @apply block flex items-center text-sm lg:text-base;
}

.interval-label {
  @apply block flex items-center text-sm lg:text-base;
}

.interval-label .icon {
  @apply m-0 mr-2 h-4 w-4;
}

.expiration-label {
  @apply block flex items-center text-sm;
}

.contract-labels, .expiration-label {
    @apply lg:text-base;
}

.expiration-label .icon {
  @apply ml-0;
}