html[lang="de"]:root{
    --text-save: "Speichern";
    --text-enter-link: "Link eingeben:";
}

html[lang="en"]:root{
    --text-save: "Save";
    --text-enter-link: "Enter link:";
}

body{
    @apply bg-deutsche-gray;
}

.outlet{
    @apply px-24;
}