.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  margin: 2em 0;
  @apply shadow-[0_1px_4px_0_rgba(0,0,0,0.16)]
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}