header.header{
    @apply flex h-16 font-semibold text-deutsche-blue text-lg shadow-lg mb-8 justify-between bg-white items-center relative z-50;
}

.header-box-left{
    @apply flex items-center justify-between h-full;
}

.header-box-right{
    @apply flex justify-between items-center;
}