.ant-popover-message {
  @apply flex items-center;
}

.ant-popover-message-icon {
  @apply m-0;
}

.ant-popover-buttons {
  @apply flex;
}