.contract-list {
    @apply m-auto w-3/4;
}

.contract-list select {
    @apply h-7;
}

.contract-list-header {
    @apply flex justify-between items-center;
}

.contract {
    @apply bg-white my-5 p-5 flex hover:cursor-pointer;
}

.contract-internals {
    @apply mr-5 w-10/12;
}

.contract-internals h1 {
    @apply text-xl mb-4;
}

.contract-internals div {
    @apply text-base;
}

.contract-internals li {
    @apply list-none ml-0 gap-2;
}

.contract-internals ul {
    @apply p-0;
}

.contract-labels {
    @apply flex mt-4 gap-6 text-sm;
}

.contract-labels .icon {
    @apply m-0 mr-2 h-4 w-4;
}

.no-contracts {
    @apply grid grid-rows-2 justify-items-center mt-32;
}

.no-contracts div {
    @apply text-2xl;
}
