.contract-details-main-container {
  @apply absolute top-16 left-0 w-full flex flex-col h-fit lg:flex-row;
}

.contract-details-data-container {
  @apply bg-white min-h-fit lg:h-[calc(100vh-4rem)] lg:w-1/2 relative pl-10 pr-10 overflow-y-auto flex flex-col;
}

.contract-details-data-container-questionnaire {
  @apply bg-white lg:h-[calc(100vh-4rem)] lg:w-1/2 relative pl-10 pr-10 overflow-y-auto flex flex-col;
}

.contract-details-pdf-container {
  @apply min-h-fit pr-12 pl-12 pt-12 overflow-y-hidden overflow-x-hidden lg:w-1/2 h-[calc(100vh-4rem)] bg-deutsche-gray;
}

.contract-details-return-tab {
  @apply inline-flex mt-10 hover:cursor-pointer;
}

.contract-details-return-tab h2{
  @apply ml-4 text-[#0018A8FF] text-2xl;
}

.contract-details-description {
  @apply flex mt-5;
}

.contract-details-description br {
  @apply hidden;
}

.contract-details-description h4 {
  @apply w-full text-[#000000FF] text-lg mb-2;
}

.contract-details-checkboxes-container {
  @apply flex-col text-base text-justify;
}

.contract-details-confirmation-button-container {
  @apply text-white flex items-end;
}

.contract-details-confirmation-button-container button {
  @apply w-40 pt-1 pb-1 bg-bright-blue flex;
}

.download-blue {
  @apply fill-bright-blue;
}

.ant-input-number-group-addon {
  @apply rounded;
}