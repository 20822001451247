.ant-switch-checked {
  @apply bg-deutsche-blue;
}

.ant-popover-buttons {
  @apply justify-end;
}

.contract-creator-main-container {
  @apply absolute left-0 w-full max-w-full;
}

.contract-creator-form-container {
  @apply flex mt-6;
}

.contract-creator-form-container input {
  @apply h-8 ml-0;
}

.contract-creator-main-container input[disabled], select[disabled] {
  @apply bg-light-grey-light text-gray-400;
}

.contract-creator-form-container textarea {
  @apply w-full resize-y rounded-md border border-black;
}

.contract-creator-form-container select {
  @apply h-8 ml-0;
}

.contract-creator-form-data-container {
  @apply flex flex-col items-center w-1/2 justify-center;
}

.contract-creator-form-data-container select {
  @apply w-full mb-4 hover:cursor-pointer;
}

.ql-editor {
  @apply rounded;
}

.ql-toolbar {
  @apply bg-light-grey-light rounded-t-lg;
}

.ql-container {
  @apply bg-white h-32 mb-4 rounded-b-lg;
}

.ql-disabled {
  @apply bg-light-grey-light text-gray-400;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: var(--text-save);
}

.ql-snow .ql-tooltip[data-mode=link]::before {
  content: var(--text-enter-link);
}

.switch-button-container {
  @apply items-center h-12 flex;
}

.switch-button-container span {
  @apply mr-3;
}

.ant-picker-disabled {
  @apply bg-light-grey-light !important;
}

.ant-picker,
.ant-picker-input:focus + .ant-picker-inner {
  @apply border-dark-grey shadow-none;
}

.ant-picker:hover,
.ant-picker-input:focus + .ant-picker-inner {
  @apply border-dark-grey shadow-none;

}

.ant-picker:hover .ant-picker-inner,
.ant-picker-input + .ant-picker-inner {
  @apply border-dark-grey;
}

.ant-picker-separator {
  @apply hidden;
}