.contract-info-container {
  @apply flex mt-6 mb-10;
}

.contract-info-container span {
  @apply gap-2 pl-0;
}

.expiration-info {
  @apply flex w-fit items-center gap-2;
}

.expiration-info .icon {
  @apply ml-0;
}