.document-tab-container {
  @apply flex border-2 border-gray-300 mb-2 items-center justify-between pt-1 pb-1;
}

.document-tab-icons-container {
  @apply flex;
}

.document-tab-icon {
  @apply mr-2 hover:cursor-pointer;
}